var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        [
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-top": "1rem" },
              attrs: { label: _vm.$t(_vm.formRules.supplierType.label) }
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.supplierType.decorator,
                      expression: "formRules.supplierType.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.supplierType.name,
                    placeholder: _vm.$t(_vm.formRules.supplierType.placeholder),
                    allowClear: true
                  }
                },
                _vm._l(_vm.dataSupplierType, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.supplierNumber.label) } },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.supplierNumber ? _vm.supplierNumber : null))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }