


































import { Messages } from "@/models/enums/messages.enum";
import Vue from "vue";
export default Vue.extend({
  name: "supplierDetails",
  props: ["dataSupplierType", "supplierNumber"],
  data() {
    return {
      formRules: {
        supplierType: {
          label: "lbl_supplier_type",
          name: "supplierType",
          placeholder: "lbl_supplier_type_placeholder",
          decorator: [
            "supplierType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        supplierNumber: {
          label: "lbl_supplier_number",
        },
      },
    };
  },
});
